#tree_container {
    --border-color: #E1E1E1;
    --border-width: 1px;
    --gap: clamp(16px, 2vh, 1.5em);
    --top-gap: calc(var(--gap)/2);
    
    input{
      padding: .5em;
      margin-bottom: 1em;
      min-width: 300px;
      border-radius: 4px;
      border: 2px solid purple;
      font-family: inherit;
    }
  
    ul{
      list-style: none;
      font-size: 14px;
      padding-inline-start: 5px;
  
      ul{
        margin-left: 1px;
        padding-inline-start: 5px;
      }
  
      > li{
        position: relative;
        padding: var(--top-gap) 0 0 var(--gap);
  
        &:last-child{
          &::before{
            content: '';
            border-left: var(--border-width) solid var(--border-color);
            border-bottom: var(--border-width) solid var(--border-color);
            border-bottom-left-radius: 4px;
            position: absolute;
            left: 0;
            top: 0;
            width: calc(var(--gap)/1.5);
            height: calc(var(--top-gap) + 1em/2);
          }
        }
  
        &:not(:last-child){
          // horizontal connector line
          background-image: linear-gradient(0, var(--border-color), var(--border-color));
          background-repeat: no-repeat;
          background-position: 0 calc(var(--top-gap) + 1em/2);
          background-size: calc(var(--gap)/1.5) var(--border-width);
  
          // vertical connector line
          border-left: var(--border-width) solid var(--border-color);
        }
      }
    }
  }